import React from 'react';

const MicrosoftAgreement = () => {
  return (
    <div>
      <p>
        These license terms are an agreement between Microsoft Corporation (or based on where you live, one of its
        affiliates) and you. Please read them. They apply to the software named above, which includes the media on which
        you received it, if any. The terms also apply to any Microsoft
      </p>

      <p>· updates,</p>

      <p>· supplements,</p>

      <p>· Internet-based services, and</p>

      <p>· support services</p>

      <p>for this software, unless other terms accompany those items. If so, those terms apply.</p>

      <p>
        <strong>
          BY USING THE SOFTWARE, YOU ACCEPT THESE TERMS. IF YOU DO NOT ACCEPT THEM, DO NOT USE THE SOFTWARE.
        </strong>
      </p>

      <p>
        <strong>If you comply with these license terms, you have the rights below.</strong>
      </p>

      <p>
        <strong>1. INSTALLATION AND USE RIGHTS. </strong>You may install and use any number of copies of the software on
        your devices to design, develop and test your programs.
      </p>

      <p>
        <strong>2. ADDITIONAL LICENSING REQUIREMENTS AND/OR USE RIGHTS.</strong>
      </p>

      <p className="paragraph-indent-level-one">
        <strong>a. Distributable Code.</strong>
      </p>

      <p className="paragraph-indent-level-two">
        <strong>i. Right to Use and Distribute. If you comply with the terms below:</strong>
      </p>

      <p className="paragraph-indent-level-two">
        · You may copy and distribute the object code form of the software (“<strong>Distributable Code</strong>”) in
        programs you develop; and
      </p>

      <p className="paragraph-indent-level-two">
        · You may permit distributors of your programs to copy and distribute the Distributable Code as part of those
        programs.
      </p>

      <p className="paragraph-indent-level-two">
        <strong>ii. Distribution Requirements. For any Distributable Code you distribute, you must</strong>
      </p>

      <p className="paragraph-indent-level-two">· add significant primary functionality to it in your programs;</p>

      <p className="paragraph-indent-level-two">
        · for any Distributable Code having a filename extension of .lib, distribute only the results of running such
        Distributable Code through a linker with your program;
      </p>

      <p className="paragraph-indent-level-two">
        · distribute Distributable Code included in a setup program only as part of that setup program without
        modification;
      </p>

      <p className="paragraph-indent-level-two">
        · require distributors and external end users to agree to terms that protect it at least as much as this
        agreement;{' '}
      </p>

      <p className="paragraph-indent-level-two">· display your valid copyright notice on your programs; and</p>

      <p className="paragraph-indent-level-two">
        · indemnify, defend, and hold harmless Microsoft from any claims, including attorneys’ fees, related to the
        distribution or use of your programs.
      </p>

      <p className="paragraph-indent-level-two">
        <strong>iii. Distribution Restrictions. You may not</strong>
      </p>

      <p className="paragraph-indent-level-two">
        · alter any copyright, trademark or patent notice in the Distributable Code;
      </p>

      <p className="paragraph-indent-level-two">
        · use Microsoft’s trademarks in your programs’ names or in a way that suggests your programs come from or are
        endorsed by Microsoft;
      </p>

      <p className="paragraph-indent-level-two">
        · distribute Distributable Code to run on a platform other than the Windows platform;
      </p>

      <p className="paragraph-indent-level-two">
        · include Distributable Code in malicious, deceptive or unlawful programs; or
      </p>

      <p className="paragraph-indent-level-two">
        · modify or distribute the source code of any Distributable Code so that any part of it becomes subject to an
        Excluded License. An Excluded License is one that requires, as a condition of use, modification or distribution,
        that
      </p>

      <p className="paragraph-indent-level-three">· the code be disclosed or distributed in source code form; or</p>

      <p className="paragraph-indent-level-three">· others have the right to modify it.</p>

      <p>
        <strong>3. SCOPE OF LICENSE.</strong> The software is licensed, not sold. Unless applicable law gives you more
        rights, Microsoft reserves all other rights not expressly granted under this agreement, whether by implication,
        estoppel or otherwise. You may use the software only as expressly permitted in this agreement. In doing so, you
        must comply with any technical limitations in the software that only allow you to use it in certain ways. You
        may not
      </p>

      <p>· work around any technical limitations in the software;</p>

      <p>
        · reverse engineer, decompile or disassemble the software, except and only to the extent that applicable law
        expressly permits, despite this limitation;
      </p>

      <p>
        · make more copies of the software than specified in this agreement or allowed by applicable law, despite this
        limitation;
      </p>

      <p>· publish the software for others to copy;</p>

      <p>· rent, lease or lend the software;</p>

      <p>· transfer the software or this agreement to any third party; or</p>

      <p>· use the software for commercial software hosting services.</p>

      <p>
        <strong>4. THIRD PARTY NOTICES.</strong> The software may include third party components with separate legal
        notices or governed by other agreements, as may be described in the ThirdPartyNotices file accompanying the
        software. Even if such components are governed by other agreements, the disclaimers and the limitations on and
        exclusions of damages below also apply.
      </p>

      <p>
        <strong>5. BACKUP COPY.</strong> You may make one backup copy of the software. You may use it only to reinstall
        the software.
      </p>

      <p>
        <strong>6. DOCUMENTATION.</strong> Any person that has valid access to your computer or internal network may
        copy and use the documentation for your internal, reference purposes.
      </p>

      <p>
        <strong>7. EXPORT RESTRICTIONS.</strong> The software is subject to United States export laws and regulations.
        You must comply with all domestic and international export laws and regulations that apply to the software.
        These laws include restrictions on destinations, end users and end use. For additional information, see
        www.microsoft.com/exporting.
      </p>

      <p>
        <strong>8. SUPPORT SERVICES. </strong>Because this software is “as is,” we may not provide support services for
        it.
      </p>

      <p>
        <strong>9. ENTIRE AGREEMENT.</strong> This agreement, and the terms for supplements, updates, Internet-based
        services and support services that you use, are the entire agreement for the software and support services.
      </p>

      <p>
        <strong>10. APPLICABLE LAW.</strong>
      </p>

      <p className="paragraph-indent-level-one">
        <strong>a. United States.</strong> If you acquired the software in the United States, Washington state law
        governs the interpretation of this agreement and applies to claims for breach of it, regardless of conflict of
        laws principles. The laws of the state where you live govern all other claims, including claims under state
        consumer protection laws, unfair competition laws, and in tort.
      </p>

      <p className="paragraph-indent-level-one">
        <strong>b. Outside the United States.</strong> If you acquired the software in any other country, the laws of
        that country apply.
      </p>

      <p>
        <strong>11. LEGAL EFFECT.</strong> This agreement describes certain legal rights. You may have other rights
        under the laws of your country. You may also have rights with respect to the party from whom you acquired the
        software. This agreement does not change your rights under the laws of your country if the laws of your country
        do not permit it to do so.
      </p>

      <p>
        <strong>
          12. DISCLAIMER OF WARRANTY. THE SOFTWARE IS LICENSED “AS-IS.” YOU BEAR THE RISK OF USING IT. MICROSOFT GIVES
          NO EXPRESS WARRANTIES, GUARANTEES OR CONDITIONS. YOU MAY HAVE ADDITIONAL CONSUMER RIGHTS OR STATUTORY
          GUARANTEES UNDER YOUR LOCAL LAWS WHICH THIS AGREEMENT CANNOT CHANGE. TO THE EXTENT PERMITTED UNDER YOUR LOCAL
          LAWS, MICROSOFT EXCLUDES THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </strong>
      </p>

      <p>
        <strong>
          FOR AUSTRALIA - You have statutory guarantees under the Australian Consumer Law and nothing in these terms is
          intended to affect those rights.
        </strong>
      </p>

      <p>
        <strong>
          13. LIMITATION ON AND EXCLUSION OF REMEDIES AND DAMAGES. YOU CAN RECOVER FROM MICROSOFT AND ITS SUPPLIERS ONLY
          DIRECT DAMAGES UP TO U.S. $5.00. YOU CANNOT RECOVER ANY OTHER DAMAGES, INCLUDING CONSEQUENTIAL, LOST PROFITS,
          SPECIAL, INDIRECT OR INCIDENTAL DAMAGES.
        </strong>
      </p>

      <p>This limitation applies to</p>

      <p>
        · anything related to the software, services, content (including code) on third party Internet sites, or third
        party programs; and
      </p>

      <p>
        · claims for breach of contract, breach of warranty, guarantee or condition, strict liability, negligence, or
        other tort to the extent permitted by applicable law.
      </p>

      <p>
        It also applies even if Microsoft knew or should have known about the possibility of the damages. The above
        limitation or exclusion may not apply to you because your country may not allow the exclusion or limitation of
        incidental, consequential or other damages.
      </p>
    </div>
  );
};

export default MicrosoftAgreement;

import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

type axiosResponseError = AxiosResponse | AxiosError;
export default class Agent {
  constructor() {
    axios.interceptors.request.use(
      (config) => {
        config.headers = { ...config.headers, 'content-type': 'application/json' };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(undefined, (error) => {
      if (error.message === 'Network Error' && !error.response) {
        return error;
      }

      throw error;
    });
  }

  set baseUrl(baseUrl: string) {
    axios.defaults.baseURL = baseUrl;
  }

  get baseUrl() {
    return axios.defaults.baseURL;
  }

  private isAxiosResponse = (response: axiosResponseError) => {
    return (response as AxiosResponse).data !== undefined;
  };

  responseBody = (response: axiosResponseError) => {
    if (this.isAxiosResponse(response)) {
      return (response as AxiosResponse).data;
    } else {
      throw response; // throw AxiosError to be caught in stores
    }
  };

  requests = {
    get: (url: string, config?: AxiosRequestConfig) => axios.get(url, config).then(this.responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(this.responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(this.responseBody),
    del: (url: string) => axios.delete(url).then(this.responseBody)
  };

  RPAVersionMaps = {
    getTargetLink: () => this.requests.get('api/rpaversionmap/RPAA')
  };
}

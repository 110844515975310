import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Logo from './common/Logo';
import Agreement from './features/agreement/Agreement';
import PostDownload from './features/post-download/PostDownload';
import { RoutePath } from './models/routePaths';

const App = () => {
  return (
    <Fragment>
      <div className="app-container">
        <div className="app-content">
          <Logo />
          <Switch>
            <Route exact path={RoutePath.Agreement} component={Agreement} />
            <Route exact path={RoutePath.PostDownload} component={PostDownload} />
            <Route exact path={'/'}>
              <Redirect to={RoutePath.Agreement} />
            </Route>
            <Route exact path={'*'}>
              <Redirect to={RoutePath.Agreement} />
            </Route>
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

export default App;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Checkbox, Button } from '@nintex-ux/react-core';
import '@nintex-ux/icons-svg-monochrome/icons/utility/download.svg';
import './styles/Agreement.css';
import { Labels, Links } from '../../models/constants';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../models/routePaths';
import MicrosoftAgreement from './MicrosoftAgreement';
import NintexAgreement from './NintexAgreement';
import Agent from '../../api/agent';
import { IRPAVersionMap } from '../../models/rpaversionmap/rpaversionmap';

const Agreement = () => {
	const [checkBoxNintex, setcheckBoxNintex] = useState(true);
	const [checkBoxMicrosoft, setcheckBoxMicrosoft] = useState(true);
	const [agreeAll, setagreeAll] = useState(true);
	const history = useHistory();

	const handleCheckboxChange = useCallback(() => {
		setagreeAll(checkBoxMicrosoft && checkBoxNintex);
	}, [checkBoxMicrosoft, checkBoxNintex]);

	useEffect(() => {
		handleCheckboxChange();
	}, [handleCheckboxChange, checkBoxNintex, checkBoxMicrosoft]);

	const handleDownloadClick = async () => {
		history.push(RoutePath.PostDownload);

		const currentHost = new URL(window.location.origin).hostname;
		const findTerm = (term: string) => {
		  if (currentHost.includes(term)){
			return currentHost;
		  }
		};

		var agent = new Agent();
		switch (currentHost) {
		  case findTerm('staging'):
			  agent.baseUrl = Links.RPAVersionMapStagingBaseUrl;
			  break;
		  case findTerm('test'):
		  case findTerm('localhost'):
				agent.baseUrl = Links.RPAVersionMapTestBaseUrl;
			  break;
		  default:
			  agent.baseUrl = Links.RPAVersionMapBaseUrl;
			  break;
		};
		
		const rpaVersionMap: IRPAVersionMap = await agent.RPAVersionMaps.getTargetLink();

		window.location.href = rpaVersionMap.targetLink;
	};

	return (
		<Fragment>
			<p className="intro-text">{Labels.IntroText}</p>
			<div className="row">
				<div className="col">
					<div className="agreement-outter-box">
						<div className="agreement-inner-box">
							<div className="agreement-container">
								<div className="text-center">
									<p className="agreement-title">{Labels.NintexAgreementHeader}</p>
								</div>
								<NintexAgreement />
							</div>
						</div>
						<div className="agreement-checkbox">
							<Checkbox
								checked={checkBoxNintex}
								id="nintex"
								label={Labels.AgreementCheckBox}
								name="nintex"
								onChange={() => setcheckBoxNintex(!checkBoxNintex)}
							/>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="agreement-outter-box">
						<div className="agreement-inner-box">
							<div className="agreement-container">
								<div className="text-center">
									<p className="agreement-title">
										{Labels.MicrosoftAgreementHeaderOne} <br />
										{Labels.MicrosoftAgreementHeaderTwo}
									</p>
								</div>
								<MicrosoftAgreement />
							</div>
						</div>
						<div className="agreement-checkbox">
							<Checkbox
								checked={checkBoxMicrosoft}
								id="microsoft"
								label={Labels.AgreementCheckBox}
								name="microsoft"
								onChange={() => setcheckBoxMicrosoft(!checkBoxMicrosoft)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="text-right download-button">
				<Button icon="download" onClick={() => handleDownloadClick()} disabled={!agreeAll}>
					{Labels.DownloadButton}
				</Button>
			</div>
		</Fragment>
	);
};

export default Agreement;

import React, { Fragment } from 'react';
import { Labels, Links } from '../../models/constants';
import './styles/PostDownload.css';

const PostDownload = () => {
  return (
    <Fragment>
      <div className="center-block">
        <div className="text-center center-block-content">
          <p>
            {Labels.DownloadStartedMessage}
            <br />
            Follow the <a href={Links.QuickStartGuide}>Quick start guide</a> for assistance.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default PostDownload;

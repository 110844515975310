import React from 'react';

const Logo = () => {
  return (
    <div className="nx-logo app-logo">
      <img src="/assets/nintex-logo.svg" className="nx-icon" alt="Nintex Logo" />
    </div>
  );
};

export default Logo;

export class Labels {
  static readonly AgreementCheckBox =
    'I accept the terms in the license agreement and if I am the administrator I am authorized to accept on behalf of my organization';

  static readonly IntroText =
    'To download and install Nintex RPA, you must agree to the Nintex Master Subscription Agreement. Because Microsoft SQL Server Local DB is a prerequisite for Nintex RPA Central, you must also agree to the Microsoft Software License Terms.';

  static readonly DownloadButton = 'Download installer';

  static readonly NintexAgreementHeader = 'NINTEX MASTER SUBSCRIPTION AGREEMENT';

  static readonly MicrosoftAgreementHeaderOne = 'MICROSOFT SOFTWARE LICENSE TERMS';

  static readonly MicrosoftAgreementHeaderTwo = 'MICROSOFT SQL SERVER 2017 LOCALDB';

  static readonly DownloadStartedMessage = 'The download of Nintex RPA Central has started. Once completed open the installer file.';
}

export class Links {
  static readonly QuickStartGuide = 'https://help.nintex.com/en-US/rpa/#cshid=quickstartguide';
  
  static readonly DownloadRPACRelative = '/NintexRpaCentral';

  static readonly RPAVersionMapBaseUrl = 'https://wus-api-rpa.nintex.io';
  static readonly RPAVersionMapTestBaseUrl = 'https://wus-api-rpa.nintextest.io';
  static readonly RPAVersionMapStagingBaseUrl = 'https://wus-api-rpa.nintexstaging.io';

  // Uncomment this and set appropriately when testing against a local RPA API
  //static readonly RPAVersionMapTestBaseUrl = 'https://localhost:5005';
}

import React from 'react';

const NintexAgreement = () => {
  return (
    <div>
      <p>
        This Nintex Master Subscription Agreement (“Agreement”) is between Nintex and the customer that has purchased a
        subscription to the Service (“Customer”). “Nintex” means the Nintex affiliate designated on the applicable Order
        Form (as defined below). If you are an individual using the Service on behalf of a corporation, partnership, or
        other entity, then that entity will be the Customer, and you represent and warrant that you are authorized to
        enter into this Agreement on behalf of such entity and bind such entity to the terms and conditions of this
        Agreement. Each of Nintex and Customer is a “Party” and together they are the “Parties.”
      </p>

      <p>
        This Agreement applies only to the Service listed in the applicable Order Form or any amendment thereto. Nintex
        reserves the right to make other Nintex software or services available under separate agreements. This Agreement
        includes any attachments and referenced policies, including without limitation the applicable Nintex Support
        Policy and Order Form entered into between the Parties.
      </p>

      <p>
        Customers are responsible for maintaining active licenses and complying with all applicable agreements for all
        Connected Services and Workflow Environments. Customers deploying Nintex Workflow and/or Forms by way of
        Microsoft Office 365 shall maintain an active Microsoft Office 365 subscription containing SharePoint Online
        with support for the Workflow Manager service.
      </p>

      <p>
        From time to time, Nintex may modify this Agreement. Unless otherwise specified by Nintex, changes become
        effective for existing subscription Customers upon renewal of the then-current Subscription Term. Customer may
        be required to click to accept the modified Agreement before using the Nintex Service in a Renewal Subscription
        Term, and in any event, continued use of the Service during the Renewal Subscription Term will constitute
        Customer’s acceptance of the version of the Agreement in effect at the time the Renewal Subscription Term
        begins.
      </p>

      <p>
        IMPORTANT: IF CUSTOMER DOES NOT AGREE TO THE TERMS AND CONDITIONS, CUSTOMER MAY NOT ACCESS OR USE THE SERVICE.
        BY CHECKING THE ACCEPTANCE BOX OR INSTALLING OR USING ANY PORTION OF THE SERVICE, CUSTOMER IS ACCEPTING ALL OF
        THE TERMS AND CONDITIONS OF THIS AGREEMENT.
      </p>

      <p>
        <strong>1. SERVICE SUBSCRIPTION</strong>
      </p>

      <p>
        <strong>1.1 Provision of Service. </strong>The Service is provided by Nintex on a subscription basis for a set
        term designated herein or in the applicable Order Form. The Service may be delivered to Customer: (a) as
        software to be installed on Customer’s designated on-premises server; (b) for integration with Customer’s
        Workflow Environments, and/or (c) as an online service, as designated in the applicable Order Form.
      </p>

      <p>
        <strong>1.2 Trial Access. </strong>If Nintex has made available to Customer free, trial, or evaluation access to
        the Service (“Trial Access”), such access is limited to evaluating the Service to determine whether to purchase
        a subscription from Nintex. Customer may not use Trial Access for any other purposes, including but not limited
        to competitive analysis, commercial, professional, or for-profit purposes. Nintex has the right to terminate
        Trial Access at any time. Unless Customer purchases a subscription for the Service, upon any such termination or
        expiration Customer’s Trial Access will cease. If Customer purchases a subscription to the Service, all of the
        terms and conditions in this Agreement will apply to such purchase and the use of the Service. NOTWITHSTANDING
        ANYTHING IN THIS AGREMEENT TO THE CONTRARY, NINTEX WILL HAVE NO WARRANTY, INDEMNITY, SUPPORT, OR OTHER
        OBLIGATIONS WITH RESPECT TO TRIAL ACCESS.
      </p>

      <p>
        <strong>1.3 License to Use the Service. </strong>Nintex owns all rights, title and interest in and to the
        Service. Nintex hereby grants Customer a non-exclusive, non- transferable, right to access and use the Service,
        solely for its own internal business purposes during the Subscription Term, subject to the terms and conditions
        of this Agreement and any restrictions and limitations designated in the applicable Order Form. Subject to the
        limited rights expressly granted hereunder, Nintex reserves all rights, title, and interest in and to the
        Service, including all related Intellectual Property Rights. No rights are granted to Customer hereunder other
        than as expressly set forth herein.
      </p>

      <p>
        <strong>1.4 Restrictions. </strong>Customer must not (a) modify, copy or create any derivative works based on
        the Service; (b) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share, offer
        in a Service bureau, or otherwise make the Service available to any third party; (c) frame or mirror any content
        forming part of the Service, other than on Customer’s own intranet for Customer internal business purposes as
        permitted in this Agreement; (d) use the Service to store or transmit infringing, libelous, or otherwise
        unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights, (e)
        use the Service to store or transmit Harmful Code, (f) interfere with or disrupt the integrity or performance of
        the Service, (g) attempt to gain unauthorized access to the Service or its related systems or networks, (h)
        permit direct or indirect access to or use of the Service in a way that circumvents the Purchased Volumes or any
        other applicable contractual usage limit, (i) reverse engineer or decompile any portion of the Service,
        including but not limited to, any software utilized by Nintex in the provision of the Service, except to the
        extent required by applicable law; (j) access the Service in order to build any commercially available product
        or Service or otherwise commercially exploit the Service; or (k) copy any features, functions, integrations,
        interfaces, or graphics of the Service. Customer’s use of the Service is subject to the Nintex Customer Use
        Policy, available at{' '}
        <a href="https://nintex.com/company/legal" target="_blank" rel="noopener noreferrer">
          https://nintex.com/company/legal
        </a>
        .
      </p>

      <p>
        <strong>1.5 Support. </strong>During the Subscription Term, Nintex will provide Support in accordance with the
        Nintex Subscription Support Policy, available at{' '}
        <a href="https://nintex.com/company/legal" target="_blank" rel="noopener noreferrer">
          https://nintex.com/company/legal
        </a>{' '}
        and the applicable Support Documentation. Support is subject to the terms of this Agreement, the Order Form, and
        the Nintex Subscription Support Policy.
      </p>

      <p>
        <strong>2. USE OF THE SERVICE</strong>
      </p>

      <p>
        <strong>2.1 Purchased Volumes. </strong>The Service provided to Customer is subject to the Purchased Volumes and
        other limitations set forth in the Order Form. Customer may increase the Purchased Volumes at any time during
        the term of the Agreement. If Customer’s usage is in excess of the Purchased Volumes, and excess usage is not
        provided for in the applicable Order Form, Nintex will notify and work with the Customer to bring usage within
        the Purchased Volumes. If Customer’s usage is not within the Purchased Volumes. If Customer’s usage of the
        Service continues to exceed the Purchased Volumes at the at the end of the thirty (30) day period after Customer
        is notified of the excess usage, , Nintex may: (a) suspend Customer’s access to the Service, and/or (b) require
        Customer execute an Order Form to purchase additional quantities based on the excess usage price set forth in
        the applicable Order Form or Nintex’s add-on pricing schedule, as applicable (each, an “Overage Order Form”).
        Each Overage Order Form will have a subscription period coterminous with the applicable Initial or Renewal
        Subscription Term.
      </p>

      <p>
        <strong>2.2 Customer Responsibilities and Customer Content. </strong>Customer will use the Service solely for
        its internal business purposes and not for the benefit of any third parties. Customer will (a) be responsible
        for Users’ compliance with this Agreement, (b) be responsible for the accuracy, quality, appropriateness, and
        legality of any Customer data or other business information (“Customer Content”) used in the Service,
      </p>

      <p>
        (c) use commercially reasonable efforts to prevent unauthorized access to or use of the Service, and notify
        Nintex promptly of any such unauthorized access or use, and (d) use the Service only in accordance with the
        terms of this Agreement and applicable laws and government regulations. Customer is the sole and exclusive owner
        of all Customer Content. Customer grants Nintex a worldwide, non-exclusive, royalty-free right and license to
        host, transfer, display, perform, reproduce, modify, and distribute Customer Content, in whole or in part, in
        any media formats and through any media channels (now known or hereafter developed) but only for the sole
        purpose of providing the Service to Customer and addressing any service or technical problems.
      </p>

      <p>
        <strong>2.3 Nintex Responsibilities. </strong>For the duration of the Subscription Term, Nintex shall implement
        and maintain administrative, physical, and technical safeguards that meet then-current and relevant industry
        standards related to the privacy, security, confidentiality, integrity, and availability of Customer Content,
        including, without limitation, by maintaining a written information security program that includes applicable
        policies, procedures, training, and technology controls designed to protect Customer Content from unauthorized
        access, use, disclosure, alteration, or destruction. Nintex will use or process Customer Content consistent with
        this Agreement and Nintex’s Privacy Policy at{' '}
        <a href="https://www.nintex.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
          https://www.nintex.com/legal/privacy-policy
        </a>{' '}
        (the “Privacy Policy”). Nintex shall retain the right to collect usage telemetry and other statistics from the
        Service to be used to monitor compliance with applicable usage limits, and for diagnostic, operational,
        performance, analytics, and product improvement purposes. The Service may be subject to limitations, delays, and
        other problems inherent in the use of the internet and electronic communications. Nintex is not responsible for
        any delays, delivery failures, or other damage resulting from such problems.
      </p>

      <p>
        <strong>2.4 Retention of Certain Content and Applications. </strong>During the Subscription Term, Nintex shall
        retain any electronic content submitted by or for Customer to the Nintex App Studio service for use in building
        custom branded mobile workflow applications (collectively, the “App Studio Submitted Content”). Nintex is not
        responsible or liable for the deletion, correction, destruction, damage, or loss of any App Studio Submitted
        Content. Upon termination of the Subscription Term, Nintex reserves the right to delete any App Studio Submitted
        Content.
      </p>

      <p>
        <strong>3. FEES AND PAYMENT</strong>
      </p>

      <p>
        <strong>3.1 Fees. </strong>Customer must pay all fees specified in all Order Forms (the “Fees”). Except as
        otherwise stated in an Order Form, all Fees are quoted and payable in U.S. dollars and are based on Service
        rights acquired and not actual usage. Except as otherwise stated in an Order Form or as provided herein,
        payments must be made on an annual basis in advance.
      </p>

      <p>
        <strong>3.2 Non-cancelable and non-refundable</strong>. All payment obligations under any and all Order Forms
        are non-cancelable and all payments made are non- refundable. The license rights for the Purchased Volumes set
        forth on any respective Order Form cannot be decreased during the Initial Term.
      </p>

      <p>
        <strong>3.3 Invoicing and Payment. </strong>Fees for the Service will be invoiced in accordance with the
        relevant Order Form. The first year’s Fees, as specified in each Order Form, are due and payable upon Customer’s
        execution of such Order Form. All other Fees due hereunder (except fees subject to good faith dispute) will be
        due and payable within thirty (30) days of invoice date. Customer will provide Nintex with complete and accurate
        billing and contact information.
      </p>

      <p>
        <strong>3.4 Overdue Payments</strong>. Any payment not received from Customer by the due date may accrue (except
        with respect to charges then under reasonable and good faith dispute), at Nintex’s discretion, late charges at
        the rate of 0.5% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower,
        from the date such payment was due until the date paid.
      </p>

      <p>
        <strong>3.5 Non-Payment and Suspension of Service. </strong>If Customer’s account is more than thirty (30) days
        past due (except with respect to charges subject to a reasonable and good faith dispute), in addition to any
        other rights or remedies it may have under this Agreement or by law, Nintex reserves the right to suspend the
        Service upon thirty (30) days written notice, without liability to Customer, until such amounts are paid in
        full.
      </p>

      <p>
        <strong>3.6 Taxes</strong>. Except as otherwise stated in an Order Form, Nintex’s fees do not include any direct
        or indirect local, state, federal or foreign taxes, levies, duties, or similar governmental assessments of any
        nature, including value-added, excise, use, or withholding taxes (collectively, “Taxes”). Customer is
        responsible for paying all Taxes associated with its acquisitions hereunder, this Agreement, and the Service,
        excluding U.S. income taxes on Nintex. If Customer has an obligation to withhold any amounts under any law or
        tax regime (other than U.S. income tax law), Customer will gross up the payments so that Nintex receives the
        amount actually quoted and invoiced. If Nintex has a legal obligation to pay or collect Taxes for which Customer
        is responsible under this section, the appropriate amount will be invoiced to and paid by Customer, unless
        Customer provides Nintex with a valid tax exemption certificate authorized by the appropriate taxing authority.
      </p>

      <p>
        <strong>4. CONFIDENTIALITY</strong>
      </p>

      <p>
        <strong>4.1 Definition of Confidential Information</strong>. “Confidential Information” means all information
        disclosed by a Party (“Disclosing Party”) to the other Party (“Receiving Party”), whether orally or in writing,
        in connection with this Service, that is designated as confidential or that reasonably should be understood by
        the Receiving Party to be confidential given the nature of the information and the circumstances of disclosure.
        Nintex Confidential Information includes the Service and Documentation. Customer Confidential Information
        includes Customer Content. Confidential Information of each Party includes code, business and marketing plans,
        financial information, technology and technical information, inventions, know-how, product plans and designs,
        and business processes disclosed by such Party. However, Confidential Information does not include any
        information that (i) is or becomes generally known to the public without breach of any obligation owed to the
        Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without
        breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without breach of
        any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party.
      </p>

      <p>
        <strong>4.2 Protection of Confidential Information</strong>. The Receiving Party will use the same degree of
        care that it uses to protect the confidentiality of its own confidential information of like kind (but not less
        than reasonable care) (i) not to use any Confidential Information of the Disclosing Party for any purpose
        outside the scope of this Agreement, and (ii) except as otherwise authorized by the Disclosing Party in writing,
        to limit access to Confidential Information of the Disclosing Party to those of its and its Affiliates’
        employees and contractors who need that access for purposes consistent with this Agreement and who have signed
        confidentiality agreements with the Receiving Party containing protections no less stringent than those herein.
      </p>

      <p>
        <strong>4.3 Compelled Disclosure. </strong>The Receiving Party may disclose Confidential Information of the
        Disclosing Party to the extent compelled by law to do so, provided the Receiving Party gives the Disclosing
        Party prior notice of the compelled disclosure (to the extent legally permitted) and reasonable assistance, at
        the Disclosing Party's cost, if the Disclosing Party wishes to contest the disclosure. If the Receiving Party is
        compelled by law to disclose the Disclosing Party’s Confidential Information as part of a civil proceeding to
        which the Disclosing Party is a party, and the Disclosing Party is not contesting the disclosure, the Disclosing
        Party will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access to
        that Confidential Information.
      </p>

      <p>
        <strong>4.4. No Intellectual Property Rights. </strong>THE RECEIVING PARTY ACQUIRES NO INTELLECTUAL PROPERTY
        RIGHTS FROM THE DISCLOSING PARTY UNDER THIS AGREEMENT, except for the restricted right to use disclosing Party’s
        Confidential Information for the express, limited purposes described above.
      </p>

      <p>
        <strong>5. LIMITATION OF LIABILITY.</strong>
      </p>

      <p>
        <strong>5.1 </strong>EXCEPT AS OTHERWISE PROVIDED IN THIS AGREEMENT, NINTEX PROVIDES THE SERVICE "AS IS", "WITH
        ALL FAULTS" AND "AS AVAILABLE". TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NINTEX MAKES NO (AND
        SPECIFICALLY DISCLAIMS ALL) REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
        OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE OR
        FREE OF HARMFUL COMPONENTS, THAT THE CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED, OR ANY IMPLIED
        WARRANTY OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON- INFRINGEMENT, AND
        ANY WARRANTY ARISING OUT OF ANY COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE. SOME JURISDICTIONS
        DO NOT ALLOW THE FOREGOING EXCLUSIONS. IN SUCH AN EVENT, SUCH EXCLUSION WILL NOT APPLY SOLELY TO THE EXTENT
        PROHIBITED BY APPLICABLE LAW. THE LIMITED WARRANTIES PROVIDED HEREIN ARE THE SOLE AND EXCLUSIVE WARRANTIES
        PROVIDED TO CUSTOMER IN CONNECTION WITH THE PROVISION OF THE SERVICE.
      </p>

      <p>
        <strong>5.2 </strong>IN NO EVENT SHALL NINTEX’S LIABILITY FOR ANY CLAIM ARISING OUT OF OR RELATING TO THE
        SERVICE AND/OR THIS AGREEMENT AND/OR ANY OTHER AGREEMENT BETWEEN THE CUSTOMER AND NINTEX EXCEED THE TOTAL AMOUNT
        PAID BY CUSTOMER TO NINTEX TO USE THE SERVICE DURING THE TWELVE (12) MONTHS PRIOR TO THE DATE OF THE CLAIM. IN
        NO EVENT SHALL NINTEX BE LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL,
        CONSEQUENTIAL, OR OTHER DAMAGES OR COSTS OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS,
        GOODWILL, USE, OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE SERVICE AND/OR THIS
        AGREEMENT, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE SERVICE, ANY INTERRUPTION, INACCURACY,
        ERROR OR OMISSION. THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS.
      </p>

      <p>
        <strong>6. MUTUAL INDEMNIFICATION</strong>
      </p>

      <p>
        <strong>6.1 Indemnification by Nintex. </strong>Nintex will defend, indemnify and hold Customer harmless against
        any loss, damage, or costs (including reasonable attorneys’ fees) in connection with claims, demands, suits, or
        proceedings (“<strong>Claims</strong>”) made or brought against Customer by a third party alleging that the use
        of the Service as contemplated hereunder infringes a copyright, a U.S. patent issued as of the Effective Date,
        or a trademark of a third party; provided, however, that Customer: (a) promptly gives written notice of the
        Claim to Nintex; (b) gives Nintex sole control of the defense and settlement of the Claim (provided that Nintex
        may not settle any Claim unless it unconditionally releases Customer of all liability); and (c) provides to
        Nintex, at Nintex’s cost, all reasonable assistance. Nintex will not be required to indemnify Customer in the
        event of: (w) modification of the Service by Customer, or Users in conflict with Customer’s obligations or as a
        result of any prohibited activity as set forth herein; (x) use of the Service in a manner inconsistent with the
        Documentation; (y) use of the Service in combination with any other product or Service not provided by Nintex;
        or (z) use of the Service in a manner not otherwise contemplated by this Agreement. If Customer is enjoined from
        using the Service or Nintex reasonably believe it will be enjoined, Nintex will have the right, at its sole
        option, to obtain for Customer the right to continue use of the Service or to replace or modify the Service so
        that it is no longer infringing. If neither of the foregoing options is reasonably available to Nintex, then use
        of the Service may be terminated at the option of Nintex and Nintex’s sole liability will be to refund any
        prepaid, but unused, Subscription Fees paid by Customer for such Service.
      </p>

      <p>
        <strong>6.2 Indemnification by Customer. </strong>Customer will defend, indemnify, and hold Nintex harmless from
        any Claims made or brought by a third party: (i) based upon breach of this Agreement by Customer, its employees
        and Users resulting in the unauthorized disclosure of Confidential Information; (ii) alleging that the Customer
        data or business information infringes the rights of, or has caused harm to a third party; or (iii) in
        connection with a claim arising from use of the Service in breach of this Agreement by Customer or Users;
        provided, however, that Nintex: (a) promptly gives written notice of the Claim to Customer; (b) give Customer
        sole control of the defense and settlement of the Claim (provided that Customer may not settle any Claim unless
        it unconditionally releases Nintex of all liability); and (c) provides to Customer, at Customer cost, all
        reasonable assistance.
      </p>

      <p>
        <strong>7. USAGE CERTIFICATIONS AND AUDIT RIGHTS</strong>
      </p>

      <p>
        <strong>7.1 Usage Certifications</strong>. Upon written request, Customer shall furnish to Nintex a signed
        certification certifying that Customer is using the Service pursuant to the terms of this Agreement and the
        applicable Order Form. If the Customer has deployed the Service as software on premises, no more frequently than
        once in any three month period, Customer will run a script provided by Nintex which will collect certain usage
        data from Customer’s on-premises deployment (collectively “Usage Data”). Nintex shall provide this Usage Data to
        Customer upon Customer’s written request.
      </p>

      <p>
        <strong>7.2 Nintex Audit Rights. </strong>With reasonable prior notice of at least ten (10) days, Nintex may
        audit Customer’s use of the Service to ensure compliance with the terms of this Agreement and the applicable
        Order Form. All such audits shall be conducted during regular business hours and no more frequently than twice
        in any twelve (12) month period and in a manner that does not unreasonably interfere with Customer’s business
        operations. Customer shall make available all such books, records, equipment, information and personnel, and
        provide all such cooperation and assistance, as may reasonably be requested by or on behalf of Nintex with
        respect to such audit. Customer shall be responsible for all additional fees and the reasonable costs of the
        audit in the event the audit reveals that Customer’s use of the Service exceeded any of Purchased Volumes set
        forth in the applicable Order Form.
      </p>

      <p>
        <strong>8. TERM AND TERMINATION</strong>
      </p>

      <p>
        <strong>8.1 Term. </strong>This Agreement will commence on the Effective Date and continue for a period of
        twelve (12) months (the “Initial Subscription Term”), and will automatically renew at the end of the Initial
        Subscription Term (or any renewal term) for a period of one year (each, a “Renewal Subscription Term” and,
        together with the Initial Subscription Term, the “Subscription Term”) unless either Party provides written
        notice to the other of non-renewal at least thirty (30) days before the end of the Initial Subscription Term or
        any Renewal Subscription Term. Any such renewal will be at the list price in effect at the time of such renewal.
      </p>

      <p>
        <strong>8.2 Termination</strong>. Either Party may terminate this Agreement: (a) upon thirty (30) days prior
        written notice to the other Party of a material breach by the other Party if such breach remains uncured at the
        expiration of such notice period; or (b) immediately in the event the other Party becomes the subject of a
        petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation, or assignment
        for the benefit of creditors.
      </p>

      <p>
        <strong>8.3 Effect of Termination</strong>. Upon any termination of this Agreement, Customer must, as of the
        date of such termination, immediately cease accessing or otherwise utilizing the Service and Nintex Confidential
        Information. Termination for any reason will not relieve Customer of the obligation to pay any fees accrued or
        due and payable to Nintex prior to the effective date of termination. Upon termination for cause by Nintex, all
        future amounts due under all Order Forms will be accelerated and become due and payable immediately.
      </p>

      <p>
        <strong>9. NINTEX DRAWLOOP DOCGEN® AND NINTEX DOCAUTOMATION</strong>
      </p>

      <p>
        <strong>9.1 Nintex Drawloop DocGen® and Nintex DocAutomation. </strong>The Nintex Drawloop DocGen and Nintex
        DocAutomation Services are each provided as a managed package for the Customer’s Salesforce Organization. .
        Salesforce.com is a third-party service provider and Nintex makes no representations or warranties regarding the
        functionality, operability, or Customer’s access to Salesforce.com. Customer’s access to the Nintex Drawloop
        DocGen and Nintex DocAutomation Services is subject to the availability of Customer’s Salesforce Organization
        and proper performance of the Customer’s obligations to Salesforce.com. Should Customer’s access to its
        Salesforce Organization be suspended due to non- payment of any amounts owed to Salesforce.com by Customer or a
        breach of Customer’s agreement with Salesforce.com, Customer’s access to the its Nintex Drawloop DocGen or
        Nintex DocAutomation Service shall also be accordingly suspended or terminated. Nintex shall not be liable to
        Customer for any refund or damages arising out of such suspension or termination. Nintex shall not be liable for
        performance issues or downtime of the Nintex Drawloop DocGen or Nintex DocAutomation Service to the extent
        caused by Salesforce.com or other factors outside Nintex’s control. Any unauthorized access to the Nintex
        Drawloop DocGen or Nintex DocAutomation Service or other abuse or impermissible activity in connection with the
        Nintex Drawloop DocGen or Nintex DocAutomation Service may result in immediate suspension or termination of
        Customer’s access to the Nintex Drawloop DocGen or Nintex DocAutomation Service pursuant to Section 8 of the
        Agreement.
      </p>

      <p>
        <strong>9.2 Customer Content. </strong>Customer’s use of the Nintex Drawloop DocGen or Nintex DocAutomation
        Service may result in Customer directing transmission of Customer Content outside Customer’s Salesforce
        Organization or other Workflow Environments, and/or to unaffiliated third parties or third-party applications,
        in each case as a result of Customer’s configuration of the Service. Upon such transmission of Customer Content
        by Customer, Nintex shall not be responsible for the privacy, security or integrity thereof. Nintex will not use
        or disclose Customer Content except solely in connection with processing such data in the normal course of
        Customer's use of the Nintex Drawloop DocGen or Nintex DocAutomation Service as otherwise provided for in this
        Agreement or as required by law. Nintex shall not be responsible or liable for the failure to store, deletion,
        correction, destruction, damage, or loss of any Customer Content.
      </p>

      <p>
        <strong>10. PROMAPP PROCESS MANAGEMENT SERVICE</strong>
      </p>

      <p>
        <strong>10.1 Promapp Process Management Solution. </strong>Customers who purchase a subscription to the Promapp
        Process Management Solution, a hosted central repository for managing business process information (the “Promapp
        Service”) are bound by the terms in this Agreement, the applicable Order Form, and the Nintex Customer Use
        Policy.
      </p>

      <p>
        <strong>10.2. Calculations. </strong>Nintex does not warrant the accuracy, correctness, reliability, and
        completeness of any formulae, or calculation available within the Promapp Service (“Calculations”). Customer
        acknowledges that the results from such Calculations are for informational purposes only, and that the
        assumptions used and figures generated are for purposes of illustration and reference only, and are subject to
        change depending on a variety of factors, which may not have be taken in account in the computation.
      </p>

      <p>
        <strong>11. GENERAL PROVISIONS</strong>
      </p>

      <p>
        <strong>11.1. Export Compliance. </strong>The Service and other technology Nintex makes available, and
        derivatives thereof, may be subject to export control and economic sanctions laws and regulations of the United
        States and the import laws and regulations of foreign governments. Each party represents that it is not named on
        any U.S. government denied-party list. Customer agrees that: (a) all use, exports, and imports related to this
        Agreement will be in compliance with these laws and regulations and (b) Customer shall not allow any third party
        to export, re-export, or transfer any part of the Service in violation of these laws and regulations. The
        foregoing obligations include but are not limited to Customer or a third party exporting, transferring, or
        importing the Service to: (i) to any country subject to export control embargo or economic sanctions implemented
        by any agency of the U.S. or foreign governments; (ii) to any person or entity on any of the U.S. Government’s
        Lists of Parties of Concern{' '}
        <a
          href={'https://www.bis.doc.gov/index.php/policy-guidance/lists-of-parties-of-concern'}
          target="_blank"
          rel="noopener noreferrer"
        >
          (https://www.bis.doc.gov/index.php/policy-guidance/lists-of-parties-of-concern)
        </a>{' '}
        or applicable international specially-designated parties or economic sanctions programs; (iii) to any end-user
        or for any known end-use related to the proliferation of nuclear, chemical or biological weapons or missiles,
        without first obtaining any export license or other approval that may be required by any U.S. Government agency
        having jurisdiction with respect to the transaction; or (iv) otherwise in violation of any export or import
        laws, regulations or requirements of any United States or foreign agency or authority.
      </p>

      <p>
        <strong>11.2 Entire Agreement</strong>. This Agreement and the Order Form(s) constitute the entire agreement
        between Customer and Nintex regarding the Service and supersede all prior and contemporaneous agreements,
        proposals or representations, written or oral, concerning its subject matter. No modification, amendment, or
        waiver of any provision of this Agreement will be effective unless in writing and signed by the Party against
        whom the modification, amendment or waiver is to be asserted. No provision of any purchase order or other
        business form employed by Customer, including any electronic invoicing portals and vendor registration
        processes, will supersede the terms and conditions of this Agreement, and any such document relating to this
        Agreement shall be for administrative purposes only and shall have no legal effect.
      </p>

      <p>
        <strong>11.3 Assignment</strong>. Neither Party may assign any of its rights or obligations hereunder, whether
        by operation of law or otherwise, without the other Party’s prior written consent (not to be unreasonably
        withheld); provided, however, either party may assign this Agreement in its entirety (including all Order
        Forms), without the other Party’s consent to its Affiliate or in connection with a merger, acquisition,
        corporate reorganization, or sale of all or substantially all of its assets, in which case the assignee must be
        capable of performing the obligations under this Agreement and must agree to be bound by the terms and
        conditions of this Agreement. Notwithstanding the foregoing, if a Party is acquired by, sells substantially all
        of its assets to, or undergoes a change of control in favor of, a direct competitor of the other Party, then
        such other Party may terminate this Agreement upon written notice. In the event of such a termination, Nintex
        will refund to Customer any prepaid fees covering the remainder of the term of all subscriptions. Subject to the
        foregoing, this Agreement will bind and inure to the benefit of the Parties, their respective successors and
        permitted assigns.
      </p>

      <p>
        <strong>11.4 Relationship of the Parties</strong>. The Parties are independent contractors. This Agreement does
        not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between the
        Parties.
      </p>

      <p>
        <strong>11.5 Waiver. </strong>No failure or delay by either Party in exercising any right under this Agreement
        will constitute a waiver of that right.
      </p>

      <p>
        <strong>11.6 Severability</strong>. If any provision of this Agreement is held by a court of competent
        jurisdiction to be contrary to law, the provision will be deemed null and void, and the remaining provisions of
        this Agreement will remain in effect.
      </p>

      <p>
        <strong>11.7 Force Majeure. </strong>Neither Party will be liable for any failure or delay in performance under
        this Agreement (other than for delay in the payment of money due and payable hereunder) for causes beyond that
        Party’s reasonable control and occurring without that Party’s fault or negligence, including, but not limited
        to, acts of God, acts of government, flood, fire, civil unrest, acts of terror, strikes or other labor problems
        (other than those involving Nintex or Customer employees, respectively), computer attacks or Harmful acts, such
        as attacks on or through the Internet, any Internet service provider, telecommunications or hosting facility.
        Dates by which performance obligations are scheduled to be met will be extended for a period of time equal to
        the time lost due to any delay so caused.
      </p>

      <p>
        <strong>11.8 Choice of Law and Jurisdiction</strong>. Excluding conflict of laws rules, this Agreement shall be
        governed by and construed under (a) the laws of the State of Washington, U.S. if Customer is located in North or
        South America, (b) the laws of the state of Victoria, Australia if Customer is located in Asia or Australia, or
        (c) the laws of England and Wales if Customer is located outside of North or South America, Asia and Australia.
        All disputes arising out of or in relation to this Agreement shall be submitted to the exclusive jurisdiction of
        the courts of (i) Seattle, Washington when the laws of Washington apply, (ii) Melbourne when the laws of the
        State of Victoria, Australia apply, or (iii) London when the laws of England and Wales apply. Nothing in this
        section shall restrict Nintex’s right to bring an action (including for example a motion for injunctive relief)
        against Customer in the jurisdiction where Customer’s place of business is located. The United Nations
        Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transactions
        Act, as currently enacted by any jurisdiction or as may be codified or amended from time to time by any
        jurisdiction, do not apply to this Agreement.
      </p>

      <p>
        <strong>12. DEFINITIONS</strong>
      </p>

      <p>
        <strong>"Affiliate" </strong>means any entity that directly or indirectly controls, is controlled by, or is
        under common control with the subject entity. "Control," for purposes of this definition, means direct or
        indirect ownership or control of more than 50% of the voting interests of the subject entity.
      </p>

      <p>
        <strong>"Botflow" </strong>means a series of actions using automated programs which include a manual or
        automated start and a termination or end in a physical or virtual desktop environment.
      </p>

      <p>
        <strong>"Connected Service" </strong>means any cloud service or external application that is called by the
        Service using a Nintex Connector.
      </p>

      <p>
        <strong>"Customer" </strong>means the entity listed in the Order Form.
      </p>

      <p>
        <strong>"Documentation" </strong>means Nintex’s online user guides, documentation, help and training materials,
        and the Customer Use Policy, as updated from time to time, accessible via{' '}
        <a href="https://community.nintex.com/" target="_blank" rel="noopener noreferrer">
          https://community.nintex.com
        </a>
        ,{' '}
        <a href="https://nintex.com/company/legal" target="_blank" rel="noopener noreferrer">
          https://nintex.com/company/legal
        </a>{' '}
        , or login to the applicable Service.
      </p>

      <p>
        <strong>"Harmful Code" </strong>means code, files, scripts, agents or programs intended to do harm, including,
        for example, viruses, worms, time bombs and Trojan horses.
      </p>

      <p>
        <strong>"Intellectual Property Rights"</strong> means any and all common law, statutory and other industrial
        property rights and intellectual property rights, including copyrights, trademarks, trade secrets, patents and
        other proprietary rights issued, honored or enforceable under any applicable laws anywhere in the world, and all
        moral rights related thereto.
      </p>

      <p>
        <strong>"Order Form" </strong>means the Nintex Order Form for the Service executed by Customer.
      </p>

      <p>
        <strong>"Purchased Volumes" </strong>means the applicable Workflow, Botflows, Processes forms, document
        generations, Users and mobile applications limits set forth in the Order Form.
      </p>

      <p>
        <strong>"Salesforce Organization" </strong>means the virtual space provided to an individual Customer of
        Salesforce.com.
      </p>

      <p>
        <strong>"Service" </strong>means the products and services purchased by Customer pursuant to the Order Form(s)
        and made available by Nintex subject to this Agreement.
      </p>

      <p>
        <strong>"Subscription Term" </strong>means the set term designated herein or in the applicable Order Form.
      </p>

      <p>
        <strong>"Support" </strong>means the support, assurance, new releases and related maintenance services for the
        Service described in the Support Documentation and includes the Support Documentation and all other associated
        Material provided by Nintex in relation to that support. “Material” means material in any form, including
        online, including documents, reports, products, information, data, source code, and methodologies.
      </p>

      <p>
        <strong>"Support Documentation"</strong> means any documentation provided by Nintex that is incorporated in or
        associated with Support.
      </p>

      <p>
        <strong>"Users" </strong>means the individuals who are authorized by Customer to have access to the Service.
      </p>

      <p>
        <strong>"Workflow" </strong>means a series of workflow actions which include a manual or automated start and a
        termination or end.
      </p>

      <p>
        <strong>"Workflow Environment"</strong> means the environment in which Customer deploys its Purchased Volumes,
        including, but not limited to, Microsoft Office 365 and Salesforce.com
      </p>
    </div>
  );
};

export default NintexAgreement;
